<template>
  <div class="home-area">
    Home Area2
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.home-area {
  height: 10vh;
  background-color: rgba(5, 96, 138, 0.193);
}
</style>
