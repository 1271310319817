<template>
  <div class="h-100">
    <div v-if="configJson">
      <about-area-1 />

      <about-area-2 />
    </div>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// import Navbar from '@/layouts/components/Navbars.vue'
import AboutArea1 from './AboutArea1.vue'
import AboutArea2 from './AboutArea2.vue'
// import Navbar2 from '@/components/Navbar/Navbars2.vue'
// import Footer from '@/components/Footer/Footer.vue'

export default {
  components: {
    // Navbar,
    // Navbar2,
    AboutArea1,
    AboutArea2,
    // Footer,
  },
  computed: {
    systemLanguage() {
      return this.$store.state.appConfig.system.language
    },
  },
  mounted() {
  },
  methods: {
  },
  setup() {
    const configJson = ref(null)

    fetch('/twhost/front/config.json')
      .then(response => response.json())
      .then(data => {
        configJson.value = data.home
      })

    return {
      configJson,
    }
  },
}
</script>

<style>
/* .test-color {
  background-color: red;
} */
</style>

<style lang="scss" scoped>
</style>
